import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'warna',
    name: 'colors',
    component: () => import(/* webpackChunkName: "colors" */ '../../views/pages/colors/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(31)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Warna',
          active: true,
        },
      ],
    },
  },
  {
    path: 'warna/tambah',
    name: 'colors-create',
    component: () => import(/* webpackChunkName: "colorsAdd" */ '../../views/pages/colors/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(32)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Warna',
          to: {name:'colors'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'warna/:id',
    name: 'colors-edit',
    component: () => import(/* webpackChunkName: "colorsEdit" */ '../../views/pages/colors/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(33)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Warna',
          to: {name:'colors'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]