import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'stok-opname',
    name: 'stockopnames',
    component: () => import(/* webpackChunkName: "stockopnames" */ '../../views/pages/stockopnames/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(56)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Stok Opname`,
      breadcrumb: [
        {
          text: 'Stok Opname',
          active: true,
        },
      ],
    },
  },
  {
    path: 'stok-opname/tambah',
    name: 'stockopnames-create',
    component: () => import(/* webpackChunkName: "stockopnamesAdd" */ '../../views/pages/stockopnames/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(57)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Stok Opname`,
      navActiveLink:'stockopnames',
      breadcrumb: [
        {
          text: 'Stok Opname',
          to: {name:'stockopnames'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'stok-opname/:id',
    name: 'stockopnames-show',
    component: () => import(/* webpackChunkName: "stockopnamesEdit" */ '../../views/pages/stockopnames/Show.vue'),
    meta: {
      pageTitle: `Ubah Stok Opname`,
      navActiveLink:'stockopnames',
      breadcrumb: [
        {
          text: 'Stok Opname',
          to: {name:'stockopnames'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]