import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/*
import forEach from 'lodash/forEach'
import join from 'lodash/join'
*/
export default {
  computed: {
    auth() {
      return this.$store.state.auth.auth
    }
  },
  methods: {
    incrementKey() {
      this.$store.dispatch('app/incrementKey')
    },
    toastIconVariant(variant) {
      if (variant == 'primary') return 'CheckIcon'
      else if (variant == 'warning') return 'AlertTriangleIcon'
      else if (variant == 'danger') return 'AlertCircleIcon'
      else 'InfoIcon'
    },
    notify(message, title = '', variant = 'primary') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: message,
          icon: this.toastIconVariant(variant),
          variant: variant,
        },
      }, {
        position: 'bottom-right'
      })
    },
    /*
    serverError(error) {
      if (error.status == 422) {
        let message = ""
        forEach(error.data.errors, (e) => {
          message += join(e, '<br>')
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Validasi Gagal",
            text: message,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        }, {
          position: 'bottom-right'
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error Ditemukan",
            text: error.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        }, {
          position: 'bottom-right'
        })
      }
    },
    */
    handleError({ response: { data: error } }) {
      let message = (!error || !Object.keys(error).length) ? 'Terdapat permasalahan disisi backend, harap hubungi developer !!' : error.error

      if (error.error.parent) {
        const { parent: { sqlMessage } } = error.error
        message = sqlMessage
      } else if (error.error.errors) {
        message = error.error.errors[0].message
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Oops!',
          text: message,
          icon: 'CheckIcon',
          variant: 'danger',
        },
      }, {
        position: 'bottom-right'
      })

    }
  }
}