import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'penerimaan',
    name: 'receptions',
    component: () => import(/* webpackChunkName: "receptions" */ '../../views/pages/receptions/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(48)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Penerimaan`,
      breadcrumb: [
        {
          text: 'Penerimaan',
          active: true,
        },
      ],
    },
  },
  {
    path: 'penerimaan/tambah',
    name: 'receptions-create',
    component: () => import(/* webpackChunkName: "receptionsAdd" */ '../../views/pages/receptions/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(49)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Penerimaan`,
      navActiveLink:'receptions',
      breadcrumb: [
        {
          text: 'Penerimaan',
          to: {name:'receptions'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'penerimaan/:id',
    name: 'receptions-edit',
    component: () => import(/* webpackChunkName: "receptionsEdit" */ '../../views/pages/receptions/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(50)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah Penerimaan`,
      navActiveLink:'receptions',
      breadcrumb: [
        {
          text: 'Penerimaan',
          to: {name:'receptions'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]