import {cP} from '../../utils/js/encodePrivilegeExport'

export default [
  {
    path: 'user',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../../views/pages/users/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(3)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `User Aplikasi`,
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'User',
          active: true,
        },
      ],
    },
  },
  {
    path: 'user/tambah',
    name: 'users-create',
    component: () => import(/* webpackChunkName: "usersAdd" */ '../../views/pages/users/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(4)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah User`,
      navActiveLink:'users',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'User',
          to: {name:'users'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'user/edit/:id',
    name: 'users-edit',
    component: () => import(/* webpackChunkName: "usersEdit" */ '../../views/pages/users/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(5)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah User`,
      navActiveLink:'users',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'User',
          to: {name:'users'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'user/:id',
    redirect: {name:'users-show'},
    component: () => import(/* webpackChunkName: "usersShow" */ '../../views/pages/users/Show.vue'),
    meta: {
      pageTitle: `Detail User`,
      navActiveLink:'users',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'User',
          to: {name:'users'},
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'info',
        name: 'users-show',
        component: () => import('../../views/pages/users/page/Info.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(7)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          auth:true,
          pageTitle: 'Info User',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Master & Setting',
            },
            {
              text: 'User',
              to: { name: 'users' },
            },
            {
              text: 'Info',
              active: true
            },
          ],
        }
      },
      {
        path: 'ganti-password',
        name: 'users-password',
        component: () => import('../../views/pages/users/page/Password.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(9)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          auth:true,
          pageTitle: 'Ganti Password User',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Master & Setting',
            },
            {
              text: 'User',
              to: { name: 'users' },
            },
            {
              text: 'Ganti Password',
              active: true
            },
          ],
        }
      },
      {
        path: 'privilege',
        name: 'users-privilege',
        component: () => import('../../views/pages/users/page/Privilege.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(12)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          auth:true,
          pageTitle: 'Privilege User',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Master & Setting',
            },
            {
              text: 'User',
              to: { name: 'users' },
            },
            {
              text: 'Privilege',
              active: true
            },
          ],
        }
      },
      {
        path: 'log',
        name: 'users-log',
        component: () => import('../../views/pages/users/page/Log.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(13)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          auth:true,
          pageTitle: 'Log User',
          navActiveLink:'users',
          breadcrumb: [
            {
              text: 'Master & Setting',
            },
            {
              text: 'User',
              to: { name: 'users' },
            },
            {
              text: 'Log',
              active: true
            },
          ],
        }
      }
    ]
  },
]