import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'produk',
    name: 'items',
    component: () => import(/* webpackChunkName: "items" */ '../../views/pages/items/IndexMain.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(43)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Produk`,
      navActiveLink: 'items',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Produk',
          active: true,
        },
      ],
    },
  },
  {
    path: 'produk/tambah',
    name: 'items-create',
    component: () => import(/* webpackChunkName: "itemsAdd" */ '../../views/pages/items/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(44)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Produk`,
      navActiveLink:'items',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Produk',
          to: {name:'items'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'produk/:id',
    name: 'items-edit',
    component: () => import(/* webpackChunkName: "itemsEdit" */ '../../views/pages/items/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(46)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah Produk`,
      navActiveLink:'items',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Produk',
          to: {name:'items'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]