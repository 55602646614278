import axios from '@axios'

const state = {
  item_categories:[],
  units:[],
  warehouses:[],
  items:[],
}
const mutations = {
  SET_ITEM_CATEGORIES(state, data) {
    state.item_categories = data
  },
  SET_UNITS(state, data) {
    state.units = data
  },
  SET_ITEMS(state, data) {
    state.items = data
  },
  SET_WAREHOUSES(state, data) {
    state.warehouses = data
  }
}
const actions = {
  getItemCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`v1/item-categories/select-all`).then(({data:{data}}) => {
        commit('SET_ITEM_CATEGORIES', data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  },
  getWarehouses({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`v1/warehouses/select-all`).then(({data:{data}}) => {
        commit('SET_WAREHOUSES', data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  },
  getUnits({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`v1/units/select-all`).then(({data:{data}}) => {
        commit('SET_UNITS', data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  },
  getItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`v1/items/select-all`).then(({data:{data}}) => {
        commit('SET_ITEMS', data)
        resolve(data)
      }).catch(({ response }) => {
        reject(response)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}