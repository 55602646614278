import axios from '@axios'
import isUndefined from 'lodash/isUndefined'
import Cookies from 'js-cookie'

const defaultUserData = {}
const state = {
  auth: defaultUserData,
  token: '',
}
const mutations = {
  AUTHENTICATE(state, data) {
    state.auth = data.cekAuth
    Cookies.set('bearerToken',data.cekAuth.token,{expires:7})
    Cookies.set('userData', JSON.stringify(data.cekAuth), { expires: 7 })
    localStorage.setItem('ability', JSON.stringify(data.cek))
  },
  UNAUTHENTICATE(state) {
    state.auth = {}
    state.token = ''

    Cookies.remove('userData')
    Cookies.remove('bearerToken')
    localStorage.removeItem('ability')
  },
  ASSIGN_AUTH(state, data) {
    state.auth = data
  },
}
const getters = {
  isAuth: state => {
    return !isUndefined(state.auth.id)
  }
}
const actions = {
  async authenticate({commit},payload) {
    try {
      const { data } = await axios.post(`v1/users/authenticate`, payload)
      commit('AUTHENTICATE', data[0])
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async unauthenticate({commit}) {
    try {
      // const { data } = await axios.post(`v1/auth/logout`)
      commit('UNAUTHENTICATE')
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  /*
  async getAuth({commit}) {
    try {
      const { data } = await axios.get(`v1/auth/me`)
      commit('ASSIGN_AUTH', data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  */
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}