import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'merek',
    name: 'brands',
    component: () => import(/* webpackChunkName: "brands" */ '../../views/pages/brands/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(27)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Merek',
          active: true,
        },
      ],
    },
  },
  {
    path: 'merek/tambah',
    name: 'brands-create',
    component: () => import(/* webpackChunkName: "brandsAdd" */ '../../views/pages/brands/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(28)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink:'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Merek',
          to: {name:'brands'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'merek/:id',
    name: 'brands-edit',
    component: () => import(/* webpackChunkName: "brandsEdit" */ '../../views/pages/brands/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(29)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink:'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Merek',
          to: {name:'brands'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]