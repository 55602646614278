import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'laporan',
    redirect: { name: 'reportsale' },
    component:{template:"<RouterView/>"},
    children: [
      {
        path: 'penjualan',
        name: 'reportsale',
        component: () => import(/* webpackChunkName: "report" */ '../../views/pages/reports/Sale.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(61)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          pageTitle: `Laporan Penjualan`,
          navActiveLink: 'reportsale',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Penjualan',
              active: true,
            },
          ],
        },
      },
      {
        path: 'posisi-stock',
        name: 'stockpos',
        component: () => import(/* webpackChunkName: "report" */ '../../views/pages/reports/StockPosition.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(62)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          pageTitle: `Laporan Posisi Stok`,
          navActiveLink: 'stockpos',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Posisi Stok',
              active: true,
            },
          ],
        },
      },
      {
        path: 'kartu-stock',
        name: 'stockcard',
        component: () => import(/* webpackChunkName: "report" */ '../../views/pages/reports/StockCard.vue'),
        beforeEnter: (to, from, next) => {
          if (cP(63)) return next()
          else return next({name:'error-unauthorized'})
        },
        meta: {
          pageTitle: `Laporan Kartu Stok`,
          navActiveLink: 'stockcard',
          breadcrumb: [
            {
              text: 'Laporan',
            },
            {
              text: 'Kartu Stok',
              active: true,
            },
          ],
        },
      },
    ]
  }
]