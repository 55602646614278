export default [
  {
    path: 'profile/:id',
    redirect: {name:'profiles-show'},
    component: () => import(/* webpackChunkName: "profilesShow" */ '../../views/pages/users/Show.vue'),
    meta: {
      pageTitle: `Detail User`,
      navActiveLink:'profiles',
    },
    children: [
      {
        path: 'info',
        name: 'profiles-show',
        component: () => import('../../views/pages/users/page/Info.vue'),
        meta: {
          auth:true,
          pageTitle: 'Info User',
          navActiveLink:'profiles',
        }
      },
      {
        path: 'ganti-password',
        name: 'profiles-password',
        component: () => import('../../views/pages/users/page/Password.vue'),
        meta: {
          auth:true,
          pageTitle: 'Ganti Password User',
          navActiveLink:'profiles',
        }
      },
      {
        path: 'privilege',
        name: 'profiles-privilege',
        component: () => import('../../views/pages/users/page/Privilege.vue'),
        meta: {
          auth:true,
          pageTitle: 'Privilege User',
          navActiveLink:'profiles',
        }
      },
      {
        path: 'log',
        name: 'profiles-log',
        component: () => import('../../views/pages/users/page/Log.vue'),
        meta: {
          auth:true,
          pageTitle: 'Log User',
          navActiveLink:'profiles',
        }
      }
    ]
  },
]