import { cP } from '../../utils/js/encodePrivilegeExport'
import store from '../../store'

export default [
  {
    path: 'penukaran-barang',
    name: 'returns',
    component: () => import(/* webpackChunkName: "returns" */ '../../views/pages/returns/Index.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('pos/setSyncDataFromStorage')
      store.dispatch('pos/getLocalSaleFromStorage')
      if (cP(70)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Penukaran Barang`,
      navActiveLink: 'returns',
      breadcrumb: [
        {
          text: 'POS',
        },
        {
          text: 'Penukaran Barang',
          active: true,
        },
      ],
    },
  }
]