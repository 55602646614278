import { cP } from '../../utils/js/encodePrivilegeExport'
import store from '../../store'

export default [
  {
    path: 'penjualan',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '../../views/pages/sales/Index.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('pos/setSyncDataFromStorage')
      store.dispatch('pos/getLocalSaleFromStorage')
      if (cP(66)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Penjualan Baru`,
      navActiveLink: 'sales',
      breadcrumb: [
        {
          text: 'POS',
        },
        {
          text: 'Penjualan Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: 'daftar-transaksi',
    name: 'saletransactions',
    component: () => import(/* webpackChunkName: "salesTransactions" */ '../../views/pages/saletransactions/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(74)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Daftar Transaksi`,
      navActiveLink: 'saletransactions',
      breadcrumb: [
        {
          text: 'POS',
        },
        {
          text: 'Daftar Transaksi',
          active: true,
        },
      ],
    },
  },
  {
    path: 'laporan-penjualan-kasir',
    name: 'cashiersaletransactions',
    component: () => import(/* webpackChunkName: "salesTransactions" */ '../../views/pages/sales/Sale.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(75)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Laporan Penjualan`,
      navActiveLink: 'cashiersaletransactions',
      breadcrumb: [
        {
          text: 'POS',
        },
        {
          text: 'Laporan Penjualan',
          active: true,
        },
      ],
    },
  },
]