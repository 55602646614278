import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'format-penomoran',
    name: 'numberings',
    component: () => import(/* webpackChunkName: "numberings" */ '../../views/pages/numberings/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(14)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Format Penomoran`,
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Format Penomoran',
          active: true,
        },
      ],
    },
    children: [
      {
        path: ':id',
        name: 'numberings-show',
        component: () => import('../../views/pages/numberings/Show.vue'),
        meta: {
          pageTitle: `Format Penomoran`,
          navActiveLink:'numberings',
          breadcrumb: [
            {
              text: 'Master & Setting',
            },
            {
              text: 'Format Penomoran',
              active: true,
            },
          ],
        },
      },
    ]
  }
]