import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'partner-pembayaran',
    name: 'partners',
    component: () => import(/* webpackChunkName: "partners" */ '../../views/pages/partners/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(22)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Mitra Pembayaran`,
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Mitra Pembayaran',
          active: true,
        },
      ],
    },
  },
  {
    path: 'partner-pembayaran/tambah',
    name: 'partners-create',
    component: () => import(/* webpackChunkName: "partnersAdd" */ '../../views/pages/partners/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(23)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Mitra Pembayaran`,
      navActiveLink:'partners',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Mitra Pembayaran',
          to: {name:'partners'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'partner-pembayaran/:id',
    name: 'partners-edit',
    component: () => import(/* webpackChunkName: "partnersEdit" */ '../../views/pages/partners/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(24)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah Mitra Pembayaran`,
      navActiveLink:'partners',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Mitra Pembayaran',
          to: {name:'partners'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]