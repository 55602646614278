import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  computed: {
    screenSize() {
      return this.$store.getters['app/currentBreakPoint']
    },
    currentSkin() {
      return this.$store.state.appConfig.layout.skin
    },
    auth() {
      return this.$store.state.auth.auth
    }
  },
  methods: {
    confirmation(functionName, params={}) {
      const vm = this
      Swal.fire({
        title:params.title ? params.title : `Apakah Anda yakin ?`,
        html:params.text ? params.text : ``,
        icon:`warning`,
        showCancelButton:true,
        cancelButtonText:`Tidak`,
        confirmButtonText: `Ya`,
        focusConfirm:true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value) {
          vm[functionName](params.parameter??null)
        }
      })
    }
  }
}