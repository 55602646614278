import { getField, updateField } from 'vuex-map-fields'
import isEqual from 'lodash/isEqual'
const moment = require('moment')

const itemsFilter = {
  date_1:moment().startOf('month'),
  date_2:moment().endOf('month'),
  code: '',
  category: [],
  brand: [],
  stock_term: null,
  stock: 0,
  status:null
}

const receptionsFilter = {
  date_1:moment().startOf('month'),
  date_2:moment().endOf('month'),
  contact: [],
}

const purchaseReturnsFilter = {
  date_1:moment().startOf('month'),
  date_2:moment().endOf('month'),
  contact: [],
}

const stockOpnamesFilter = {
  date_1:moment().startOf('month'),
  date_2:moment().endOf('month'),
  code: "",
}

const itemReportFilter = {
  date_1:moment().startOf('month'),
  date_2:moment().endOf('month'),
  categories: [],
  brands: [],
  colors: [],
  sizes: [],
  payment_types: [],
  type_trans:[],
  stock_term:null,
  stock:0,
}

const saleDateFilter = {
  date_1: moment().startOf('day'),
  date_2: moment().endOf('day'),
}

const transactionFilter = {
  date_1: moment().startOf('month'),
  date_2: moment().endOf('month'),
  type_transactions:null
}
// -----------------------------------------------------------
const state = {
  itemsFilter: JSON.parse(JSON.stringify(itemsFilter)),
  receptionsFilter: JSON.parse(JSON.stringify(receptionsFilter)),
  stockOpnamesFilter: JSON.parse(JSON.stringify(stockOpnamesFilter)),
  purchaseReturnsFilter: JSON.parse(JSON.stringify(purchaseReturnsFilter)),
  itemReportFilter: JSON.parse(JSON.stringify(itemReportFilter)),
  saleDateFilter: JSON.parse(JSON.stringify(saleDateFilter)),
  transactionFilter: JSON.parse(JSON.stringify(transactionFilter)),
}
const mutations = {
  updateField,
  RESET_ITEMS(state) {
    state.itemsFilter = JSON.parse(JSON.stringify(itemsFilter))
  },
  RESET_RECEPTIONS(state) {
    state.receptionsFilter = JSON.parse(JSON.stringify(receptionsFilter))
  },
  RESET_STOCK_OPNAMES(state) {
    state.stockOpnamesFilter = JSON.parse(JSON.stringify(stockOpnamesFilter))
  },
  RESET_PURCHASE_RETURNS(state) {
    state.purchaseReturnsFilter = JSON.parse(JSON.stringify(purchaseReturnsFilter))
  },
  RESET_ITEM_REPORTS(state) {
    state.purchaseReturnsFilter = JSON.parse(JSON.stringify(purchaseReturnsFilter))
  },
  RESET_TRANSACTIONS(state) {
    state.transactionFilter = JSON.parse(JSON.stringify(transactionFilter))
  },
}
const getters = {
  getField,
  isItemsFiltered(state) {
    return !isEqual(state.itemsFilter,itemsFilter)
  },
  isReceptionsFiltered(state) {
    return !isEqual(state.receptionsFilter,receptionsFilter)
  },
  isStockOpnamesFiltered(state) {
    return !isEqual(state.stockOpnamesFilter,stockOpnamesFilter)
  },
  isPurchaseReturnsFiltered(state) {
    return !isEqual(state.purchaseReturnsFilter,purchaseReturnsFilter)
  },
  isTransactionsFiltered(state) {
    return !isEqual(state.transactionFilter,transactionFilter)
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  getters
}