import axios from '@axios'
import filter from 'lodash/filter'
import chunk from 'lodash/chunk'
import forIn from 'lodash/forIn'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import uniqBy from 'lodash/uniqBy'
import { v4 as uuidv4 } from 'uuid'

const moment = require('moment')
require('moment/locale/id')

const state = {
  items: [],
  payment_types: [],
  local_transactions: []
}
const mutations = {
  SET_ITEMS_FROM_DATA(state, data) {
    state.items = data
    const chunked = chunk(data, 30)
    for (let i = 0; i < chunked.length; i++) {
      const arrays = chunked[i];
      const items = JSON.stringify(arrays)
      localStorage.setItem(`itemProducts_${i}`,items)
    }
    localStorage.setItem('itemsLastSync',moment().toString())
  },
  SET_ITEMS_FROM_STORAGE(state) {
    let data = []
    forIn(localStorage, (value, objKey) => {
      if (true == startsWith(objKey, 'itemProducts_')) {
        const items = JSON.parse(value)
        data.push(...items)
      }
    })
    state.items = data
  },
  SET_PAYMENT_TYPES_FROM_STORAGE(state) {
    const data = JSON.parse(localStorage.getItem('paymentTypes') || '[]')
    state.payment_types = data
  },
  REMOVE_STATE_ITEMS() {
    state.items = []
  },
  SET_PAYMENT_TYPES(state, data) {
    state.payment_types = data
    localStorage.setItem('paymentTypes',JSON.stringify(data))
  },
  REMOVE_PAYMENT_TYPES() {
    localStorage.payment_types = []
    localStorage.removeItem('paymentTypes')
  },
  SET_NEW_SALE(state, data) {
    const locals = JSON.parse(localStorage.getItem('localSaleTransactions') || '[]')
    locals.push({...data,...{alwaysunique: uuidv4()}})
    state.local_transactions = locals
    localStorage.setItem('localSaleTransactions',JSON.stringify(locals))
  },
  GET_SALE_FROM_STORAGE(state) {
    const data = JSON.parse(localStorage.getItem('localSaleTransactions') || '[]')
    state.local_transactions = data
  },
  ACTION_MODIFY_TRANSACTION(state, params) {
    const uniqid = params.uniqid
    const localData = JSON.parse(localStorage.getItem('localSaleTransactions') || '[]')
    const index = findIndex(localData, { uniqid: uniqid })
    if (index !== -1) {
      forEach(params, (value, key) => {
        localData[index][key] = value
      })
      localData[index]['alwaysunique'] = uuidv4()
    }
    localStorage.setItem('localSaleTransactions',JSON.stringify(localData))
  },
  ACTION_MODIFY_TRANSACTION_BATCH(state, params) {
    const { kind, uniqid, id_payment_partner } = params
    let index = findIndex(state.local_transactions, { uniqid, kind, id_payment_partner })
    if (params.alwaysunique) {
      index = findIndex(state.local_transactions,{alwaysunique: params.alwaysunique})
    }
    if (index >= 0) {
      forEach(params, (value, key) => {
        state.local_transactions[index][key] = value
      })
      state.local_transactions[index]['alwaysunique'] = uuidv4()
    }
  },
  ACTION_SAVE_STATE_TO_LOCALSTORAGE(state) {
    localStorage.setItem('localSaleTransactions',JSON.stringify(state.local_transactions))
  }
}
const getters = {
  getFilteredSize: (state) => ({ item_id, id_brand, id_color }) => {
    const ff = {}
    if (item_id) ff.item_id = item_id
    if (id_brand) ff.id_brand = id_brand
    if (id_color) ff.id_color = id_color
    return map(filter(state.items, ff),n => ({value:n.id_size,label:n.size_name}))
  },
  checkSyncStatus: (state) => {
    if (state.items.length < 1 || state.payment_types.length < 1) return false
    else return true
  },
  getItemColors: (state) => {
    return map(uniqBy(state.items,'id_color'),n => ({value:n.id_color, label:n.color_name}))
  },
  getItemSizes: (state) => {
    return map(uniqBy(state.items,'id_size'),n => ({value:n.id_size, label:n.size_name}))
  },
  getItemCategories: (state) => {
    return map(uniqBy(state.items,'id_category'),n => ({value:n.id_category, label:n.category_name}))
  },
  itemsByFilter: (state) => (filtering) => {
    let data = JSON.parse(JSON.stringify(state.items))
    if (filtering.search) {
      const search = filtering.search.toLowerCase()
      data = filter(data, n => {
        return n.code.toLowerCase().includes(search) || n.brand_name.toLowerCase().includes(search) || n.item_name.toLowerCase().includes(search)
      })
    }
    data = filter(data, n => {
      return !filtering.excludeId.includes(n.id)
    })
    delete filtering.search
    delete filtering.excludeId
    return filter(data, filtering)
  },
  onlySaleTransactions: (state) => (filtering) => {
    let data = filter(state.local_transactions, n => {
      return [1,2].includes(n.kind) && n.type_transaction == 3
    })
    const results = []
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (filtering.noDelete) {
        if(item.kind==3||item.kind==6) continue
      }
      for (let x = 0; x < item.details.length; x++) {
        const detail = item.details[x];
        if (filtering.excludeId.includes(detail.uniqid)) continue;
        results.push({
          "id_item": detail.id_item,
          "id_variant": detail.id_variant,
          "id_brand": detail.id_brand,
          "id_color": detail.id_color,
          "id_size": detail.id_size,
          "id_category": detail.id_category,
          "qty": detail.qty,
          "price": detail.price,
          "price_total": detail.price_total,
          "uniqid": detail.uniqid,
          "code_ref" : item.code_ref,
          "brand_name" : detail.brand_name,
          "code" : detail.code,
          "code_article" : detail.code_article,
          "color_name" : detail.color_name,
          "size_name" : detail.size_name,
        })
      }
    }
    return results
  },
  transactionByFilter: (state) => (filtering) => {
    return filter(state.local_transactions,filtering)
  }
}
const actions = {
  setSyncDataFromStorage: ({ commit }) => {
    commit('SET_ITEMS_FROM_STORAGE')
    commit('SET_PAYMENT_TYPES_FROM_STORAGE')
  },
  getItems: async ({ commit, dispatch }) => {
    try {
      const { data } = await axios.get(`v1/item_pos/list`)
      dispatch('removeItemStorage').then(() => {
        commit('SET_ITEMS_FROM_DATA', data)
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getPaymentTypes: async ({ commit }) => {
    try {
      const { data } = await axios.get(`v1/payment_partners/select2`)
      commit('SET_PAYMENT_TYPES', data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  postTransaction: async ({ commit },params) => {
    try {
      const { data } = await axios.post(`v1/pos/sycn`,params)
      const { uniqid, alwaysunique, kind, id_payment_partner } = params
      commit('ACTION_MODIFY_TRANSACTION_BATCH', { uniqid, kind, id_payment_partner, alwaysunique, is_sync: true })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  removeItemStorage: () => {
    return new Promise((resolve, reject) => {
      try {
        forIn(localStorage, async (value, objKey) => {
          if (true == startsWith(objKey, 'itemProducts_')) {
            await localStorage.removeItem(objKey)
          }
        })
        localStorage.removeItem('itemsLastSync')
        return resolve(true)
      } catch (error) {
        return reject(error)
      }
    })
  },
  storeSale: ({ commit }, data) => {
    if ([1,4].includes(data.kind)||data.id_origin) commit('SET_NEW_SALE', data)
    else commit('ACTION_MODIFY_TRANSACTION', data)
  },
  getLocalSaleFromStorage({ commit }) {
    commit('GET_SALE_FROM_STORAGE')
  },
  modifyLocalTransaction({ commit }, params) {
    commit('ACTION_MODIFY_TRANSACTION', params)
  },
  getTransactionFromServer: async (store, params) => {
    try {
      const { data } = await axios.get(`/v1/pos/show`, { params: params })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}