import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
// Composition API
Vue.use(VueCompositionAPI)
import { ToastPlugin, LayoutPlugin } from 'bootstrap-vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// import '@/libs/sweet-alerts'
import '@/libs/vue-select'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(LayoutPlugin)
// Vue.use(IconsPlugin)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// notify mixin
import notify from './utils/mixins/notify'
import utils from './utils/mixins/utils'
import encodePrivilege from './utils/mixins/encodePrivilege'
// import pusher from './utils/mixins/pusher'
Vue.mixin(notify)
Vue.mixin(utils)
Vue.mixin(encodePrivilege)
// Vue.mixin(pusher)

// moment
const moment = require('moment')
require('moment/locale/id')
Vue.use(require('vue-moment'), {
  moment
})
Vue.filter('fulldate', function (value) {
  if (!value) return ''
  return moment(value).format("DD MMM YYYY HH:mm")
})
Vue.filter('defaultdate', function (value) {
  if (!value) return ''
  return moment(value).format("DD MMM YY")
})
Vue.filter('mindate', function (value) {
  if (!value) return ''
  return moment(value).format("DD/MM/YY")
})
// numeral
const numeral = require('numeral')
/*
numeral.register('locale', 'id', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'Rp'
    }
});
numeral.locale('id')
*/

Vue.filter('formatNumber', function (value) {
  if (!value) return "0"
  return numeral(value).format("0,0.[0000]")
})

/*
Vue.use(require('vue-pusher'), {
    api_key: '77000e693c7d62902e54',
    options: {
        cluster: 'ap1',
        encrypted: true,
    }
});
*/
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
