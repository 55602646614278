import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './modules/auth'
import select from './modules/select'
import filter from './modules/filter'
import pos from './modules/pos'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    auth,
    select,
    filter,
    pos
  },
  strict: process.env.DEV,
})
