import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

// import moment from 'moment'
/*
const checkPrivileges = (slug) => {
  if (includes(store.state.auth.privileges, slug)) return true
  else return false
}
*/
import Cookies from 'js-cookie'

import users from './sections/users'
import profiles from './sections/profiles'
import suppliers from './sections/suppliers'
import numberings from './sections/numberings'
import partners from './sections/partners'
import productspecs from './sections/productspecs'
import itemcategories from './sections/itemcategories'
import items from './sections/items'
import receptions from './sections/receptions'
import sales from './sections/sales'
import returns from './sections/returns'
import stockopnames from './sections/stockopnames'
import purchasereturns from './sections/purchasereturns'
import reports from './sections/reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "Main" */ '../views/Main.vue'),
      name: 'main',
      redirect: { name: 'home' },
      beforeEnter: (to, from, next) => {
        // const appLoading = document.getElementById('loading-bg')
        if (Cookies.get('userData')) store.commit('auth/ASSIGN_AUTH', JSON.parse(Cookies.get('userData')))
        if (!Cookies.get('bearerToken')) {
          // if (appLoading) appLoading.style.display = 'none'
          return next({ name: 'login' })
        } else {
          try {
            // store.dispatch(`auth/getAuth`)
            // if (appLoading) appLoading.style.display = 'none'
            return next()
          } catch (error) {
            store.commit('auth/UNAUTHENTICATE')
            // if (appLoading) appLoading.style.display = 'none'
            return next({ name: 'login' })
          }
        }
      },
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
          meta: {
            pageTitle: `Dashboard`,
            breadcrumb: [
              {
                text: 'Home',
                active: true,
              },
            ],
          },
        },
        {
          path: 'pengaturan-printer',
          name: 'printersettings',
          component: () => import(/* webpackChunkName: "settings" */ '../views/pages/settings/Printer.vue'),
          meta: {
            pageTitle: `Pengaturan Printer`,
          },
        },
        ...users,
        ...suppliers,
        ...numberings,
        ...partners,
        ...productspecs,
        ...itemcategories,
        ...items,
        ...receptions,
        ...sales,
        ...returns,
        ...profiles,
        ...stockopnames,
        ...purchasereturns,
        ...reports
      ]
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "utilities" */ '../views/ResetPassword.vue'),
      beforeEnter: (to, from, next) => {
        if (!Cookies.get('bearerToken')) return next({ name: 'error-unauthorized' })
        else return next()
      },
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/coming-soon',
      name: 'coming-soon',
      component: () => import(/* webpackChunkName: "utilities" */ '../views/ComingSoon.vue'),
      meta: {
        pageTitle: 'Coming Soon',
        auth: false,
        breadcrumb: [
          {
            text: 'Coming Soon',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "utilities" */ '../views/Login.vue'),
      meta: {
        auth: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import(/* webpackChunkName: "utilities" */ '../views/error/Error404.vue'),
      meta: {
        layout: 'full',
        auth: false,
      },
    },
    {
      path: '/error-unauthorized',
      name: 'error-unauthorized',
      component: () => import(/* webpackChunkName: "utilities" */ '../views/error/Unauthorized.vue'),
      meta: {
        layout: 'full',
        auth: false,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
