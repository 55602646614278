import CryptoJS from "crypto-js";
import toString from 'lodash/toString'
const KEY = "@Hamaz21_!siaSOFT";

const validPass = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, KEY);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const cP = (id) => {
  const ids = toString(id)
  const ability = JSON.parse(localStorage.getItem('ability') || "[]")
  const res = ability.filter(e => {
    return ids === validPass(e)
  })
  return res.length>0
}

export {
  cP
}