import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'return-barang',
    name: 'purchasereturns',
    component: () => import(/* webpackChunkName: "purchasereturns" */ '../../views/pages/purchasereturns/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(52)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Return Barang`,
      breadcrumb: [
        {
          text: 'Return Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: 'return-barang/tambah',
    name: 'purchasereturns-create',
    component: () => import(/* webpackChunkName: "purchasereturnsAdd" */ '../../views/pages/purchasereturns/Form.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(53)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Return Barang`,
      navActiveLink:'purchasereturns',
      breadcrumb: [
        {
          text: 'Return Barang',
          to: {name:'purchasereturns'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'return-barang/:id',
    name: 'purchasereturns-edit',
    component: () => import(/* webpackChunkName: "purchasereturnsEdit" */ '../../views/pages/purchasereturns/Form.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(54)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah Return Barang`,
      navActiveLink:'purchasereturns',
      breadcrumb: [
        {
          text: 'Return Barang',
          to: {name:'purchasereturns'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]