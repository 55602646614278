import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'ukuran',
    name: 'sizes',
    component: () => import(/* webpackChunkName: "sizes" */ '../../views/pages/sizes/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(35)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Ukuran',
          active: true,
        },
      ],
    },
  },
  {
    path: 'ukuran/tambah',
    name: 'sizes-create',
    component: () => import(/* webpackChunkName: "sizesAdd" */ '../../views/pages/sizes/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(36)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Ukuran',
          to: {name:'sizes'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'ukuran/:id',
    name: 'sizes-edit',
    component: () => import(/* webpackChunkName: "sizesEdit" */ '../../views/pages/sizes/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(37)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink: 'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          to: {name:'productspecs'},
        },
        {
          text: 'Ukuran',
          to: {name:'sizes'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]