import brands from './brands'
import colors from './colors'
import sizes from './sizes'
import {cP} from '../../utils/js/encodePrivilegeExport'

export default [
  {
    path: 'spesifikasi-produk',
    name: 'productspecs',
    redirect: {name:'brands'},
    component: () => import(/* webpackChunkName: "productspecs" */ '../../views/pages/productspecs/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(26)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Spesifikasi Produk`,
      navActiveLink:'brands',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Spesifikasi Produk',
          active: true,
        },
      ],
    },
    children: [
      ...brands,
      ...sizes,
      ...colors
    ]
  }
]