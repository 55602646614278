import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import Router from '@/router'
import Cookies from 'js-cookie'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || `http://localhost:8000`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  (request) => {
    const d = new Date()
    const bearer = Cookies.get('bearerToken') || "";
    if(bearer) request.headers["Authorization"] = `Bearer ${bearer}`;
    request.headers["TimezoneOffset"] = d.getTimezoneOffset();
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status == 401) {
      store.commit("auth/UNAUTHENTICATE");
      Router.replace({ name: "login" });
    }
    if (response && response.status == 404) {
      Router.replace({ name: "error-404" });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
