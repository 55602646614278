import {cP} from '../../utils/js/encodePrivilegeExport'
export default [
  {
    path: 'pemasok',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "suppliers" */ '../../views/pages/suppliers/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(18)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Pemasok`,
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Pemasok',
          active: true,
        },
      ],
    },
  },
  {
    path: 'pemasok/tambah',
    name: 'suppliers-create',
    component: () => import(/* webpackChunkName: "suppliersAdd" */ '../../views/pages/suppliers/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(19)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Tambah Pemasok`,
      navActiveLink:'suppliers',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Pemasok',
          to: {name:'suppliers'},
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: 'pemasok/:id',
    name: 'suppliers-edit',
    component: () => import(/* webpackChunkName: "suppliersEdit" */ '../../views/pages/suppliers/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (cP(20)) return next()
      else return next({name:'error-unauthorized'})
    },
    meta: {
      pageTitle: `Ubah Pemasok`,
      navActiveLink:'suppliers',
      breadcrumb: [
        {
          text: 'Master & Setting',
        },
        {
          text: 'Pemasok',
          to: {name:'suppliers'},
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
]